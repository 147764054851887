import axios from './axiosConfig';

export async function fileUpload(uploadedFile) {
  try {
    const formData = new FormData();
    formData.append('file', uploadedFile);
    const resp = await axios.post('/import', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return resp.data;
  } catch (err) {
    // Handle Error Here
    console.error(err);
    return err;
  }
}
