import axios from './axiosConfig';

export async function addItem(item) {
  try {
    const response = await axios.post('/items', {
      title: item.title,
      listingType: item.listingType,
      categoryId: Number(item.categoryId),
      cityId: 79,
      price: Number(item.price),
      state: item.state,
      brandId: Number(item.brandId),
      available: item.available,
      description: item.description,
      freeDelivery: item.freeDelivery,
      barcode: item.barcode,
      content: item.content,
      type: item.type,
      photos: item.photos,
      olxPhotoIds: item.olxPhotoIds,
    });

    return response.data;
  } catch (err) {
    console.error(err);
  }
}

export async function getAllItems(skip = 0, limit = 20, searchValue) {
  try {
    const resp = await axios.get(
      `/items?skip=${skip}&limit=${limit}${
        searchValue ? `&searchTerm=${encodeURIComponent(searchValue)}` : ''
      }`
    );
    return resp.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function getItem(id) {
  try {
    const resp = await axios.get('/items/' + id);
    return resp.data;
  } catch (err) {
    console.error(err);
  }
}

export async function updateItem(item) {
  try {
    const resp = await axios.put('/items/' + item._id, item);

    return resp.data;
  } catch (err) {
    console.error(err);
  }
}

export async function deleteItem(id) {
  try {
    const resp = await axios.delete('/items/' + id);
    return resp.data;
  } catch (err) {
    // Handle Error here
    console.error(err);
    return err.response.data;
  }
}

export async function postImg(image) {
  try {
    const formData = new FormData();
    formData.append('photo', image);

    const response = await axios.post('/items/photo', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (err) {
    // Handle Error Here
    console.error(err);
    return err;
  }
}

export async function postImgArray(imgArray) {
  try {
    const formData = new FormData();
    imgArray.forEach((img) => {
      formData.append('photos', img);
    });

    const resp = await axios.post('/items/photos', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return resp.data;
  } catch (err) {
    // Handle Error Here
    console.error(err);
    return err;
  }
}

export async function deleteImages(itemId) {
  try {
    const response = await axios.post(`/items/${itemId}/photos`);

    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}
