import { Button, ListItem, ListItemText, TextField } from '@mui/material';
import React, { useState, useContext } from 'react';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { updateItem } from '../../api/itemsApi';
import { ImportContext } from '../../contexts/ImportContext';
import classes from './ImportItem.module.css';
import ReactLoading from 'react-loading';

const mapCategory = (id) => {
  switch (id) {
    case 1372:
      return 'Unisex';
    case 1371:
      return 'Ženski';
    case 1370:
      return 'Muški';
    default:
      return '';
  }
};

const ImportItem = (props) => {
  const [currentItemPrice, setCurrentItemPrice] = useState(props.data.price);
  const { updatedItems } = useContext(ImportContext);
  const [updatedItemsArray, setUpdatedItemsArray] = updatedItems;
  const [isLoading, setIsLoading] = useState(false);

  const priceChangeHandler = (event) => {
    setCurrentItemPrice(event.target.value);
  };

  const updateHandler = async () => {
    const updatedItemObject = {
      _id: props.data._id,
      price: currentItemPrice,
    };
    setIsLoading(true)
    await updateItem(updatedItemObject);

    const newUpdatedItemsArray = [...updatedItemsArray];

    const updatedItemIndex = newUpdatedItemsArray.findIndex(
      (item) => item._id === updatedItemObject._id
    );

    newUpdatedItemsArray.splice(updatedItemIndex, 1);
    setUpdatedItemsArray(newUpdatedItemsArray);
    props.renderUpdate(newUpdatedItemsArray);
    setIsLoading(false)
  };
  return (
    <ListItem
      className={props.updated ? classes.importItemUpdated : classes.importItem}
    >
      <ListItemText
        primary={`Naziv : ` + props.data.title}
        secondary={
          <React.Fragment>
            <Typography
              sx={{ display: 'block' }}
              component="span"
              variant="body2"
              color="text.primary"
            >
              {'Barkod : ' + props.data.barcode}
            </Typography>
            {!props.updated && (
              <Typography
                sx={{ display: 'block' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {`Cijena : ` + props.data.price + ' KM'}
              </Typography>
            )}

            <Typography
              sx={{ display: 'block' }}
              component="span"
              variant="body2"
              color="text.primary"
            >
              {`Kategorija : ` + mapCategory(props.data.categoryId)}
            </Typography>
          </React.Fragment>
        }
      />
      {!props.updated || (
        <>
          <br></br>
          <TextField
            className={classes.priceTextField}
            edge="end"
            id="olxPrice"
            value={props.data.olxPrice}
            placeholder={t`item.price.textFieldPlaceholder`}
            variant="outlined"
            label="OLX cijena"
            disabled
            sx={{
              width: '87px',
              padding: '0px',
              height: '24px',
              marginTop: '3px',
            }}
            inputProps={{
              style: { padding: '6px' },
            }}
          ></TextField>{' '}
          <TextField
            edge="end"
            id="price"
            value={currentItemPrice}
            onChange={priceChangeHandler}
            placeholder={t`item.price.textFieldPlaceholder`}
            variant="outlined"
            sx={{
              width: '87px',
              padding: '0px',
              marginLeft: '10px',
              marginTop: '3px',
            }}
            inputProps={{
              style: { padding: '6px' },
            }}
            label="Nova cijena"
          ></TextField>
          <Button
            variant="outlined"
            color="secondary"
            onClick={updateHandler}
            sx={{ marginLeft: '10px', marginTop: '2px', marginBottom: '15px' }}
          >
            {
              isLoading ? (
                <ReactLoading
                  type="bars"
                  color="#7ac142"
                  width={22}
                  height={22}
                />
              ) : (
                <Trans id="importItem.update.button" />
              )
            }
          </Button>
        </>
      )}
      {!props.updated ? (
        <Link
          edge="end"
          style={{ textDecoration: 'none', color: 'white' }}
          to={{
            pathname: '/item',
            state: props.data,
          }}
        >
          <Button variant="outlined" color="secondary">
            <Trans id="importItem.add.button" />
          </Button>
        </Link>
      ) : null}
    </ListItem>
  );
};

export default ImportItem;
