import axios from './axiosConfig';

export async function updateFreeDelivery(toggle) {
  try {
    const resp = await axios.post('/updateFreeDelivery', {
      freeDelivery: toggle,
    });
    return resp.status;
  } catch (err) {
    // Handle Error Here
    console.error(err);
  }
}
