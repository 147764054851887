import React from 'react'
import { styled } from '@mui/material/styles';
import { CircularProgress } from '@mui/material'

const StyledContainer = styled('div')({
  display: 'flex',
  width: '100vw',
  height: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
})

function Spinner() {
  return (
    <StyledContainer>
      <CircularProgress color="secondary" />
    </StyledContainer>
  )
}

export default Spinner
