export const typeOptions = [
  'Extrait de parfum',
  'Edp',
  'Edt',
  'Edc',
  'Eau fraiche',
  'Deo',
  'Body mist',
  'Ostalo',
];
