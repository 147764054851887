import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Container, Grid, Fab } from '@mui/material';
import ItemsList, { ITEMS_PER_PAGE } from './itemsList/ItemsList';
import { AddItemContext } from '../../contexts/AddItemContext';
import { Trans } from '@lingui/react';
import { getAllItems } from '../../api/itemsApi';
import AddIcon from '@mui/icons-material/Add';
import Navigation from '../navigation/Navigation';
import classes from './ItemsOverviewPage.module.css';
import Spinner from '../common/Spinner';

const ItemsOverview = (props) => {
  const [numberOfItems, setNumberOfItems] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [scrollToTopVisible, setScrollToTopVisible] = useState(false);
  const { useItemData } = useContext(AddItemContext);
  const [data, setData] = useItemData; // useState from useContext

  useEffect(() => {
    const fetchData = async () => {
      const itemData = await getAllItems();
      setData(itemData.items);
      setNumberOfItems(itemData.numberOfItems);
      setIsLoading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleVisibility = () => {
    if (window.pageYOffset > 50) {
      setScrollToTopVisible(true);
    } else {
      setScrollToTopVisible(false);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);
  const deleteDataHandler = (id) => {
    const newData = data.filter((item) => item._id !== id);
    setData(newData);
  };

  const onPageChangeHandler = (pageNum) => {
    const skip = (pageNum - 1) * ITEMS_PER_PAGE;

    const updateData = async () => {
      const itemData = await getAllItems(skip, ITEMS_PER_PAGE, searchValue);
      setData(itemData.items);
      setNumberOfItems(itemData.numberOfItems);
    };

    updateData();
  };
  const searchHandler = (event) => {
    setSearchValue(event.target.value);
  };
  const clearSearchValue = () => {
    setSearchValue('');
  };
  const enterSubmitSearchHandler = (e) => {
    if (e.key === 'Enter') {
      submitSearchHandler();
    }
  };

  const submitSearchHandler = async (e) => {
    const searchQueryLocal = await getAllItems(0, ITEMS_PER_PAGE, searchValue);
    setData(searchQueryLocal.items);
    setNumberOfItems(searchQueryLocal.numberOfItems);
  };

  return (
    <>
      <Navigation
        title={<Trans id="itemsOverviewPage.title.appBarTitle" />}
        searchHandler={searchHandler}
        enterSubmitSearchHandler={enterSubmitSearchHandler}
        submitSearchHandler={submitSearchHandler}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        clearSearchValue={clearSearchValue}
      ></Navigation>
      {isLoading ? (
        <Spinner />
      ) : (
        <Container maxWidth="lg" className={classes.container}>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} className={classes.itemsTable}>
                {data?.length !== 0 ? (
                  <ItemsList
                    onPageChangeHandler={onPageChangeHandler}
                    data={data}
                    numberOfItems={numberOfItems}
                    deleteDataHandler={deleteDataHandler}
                  />
                ) : (
                  <p className={classes.noResults}>
                    <Trans id="ItemsOverviewPage.noResults.pharagraph" />
                  </p>
                )}
              </Grid>
            </Grid>
          </div>
          {scrollToTopVisible ? (
            <Link to="/item" style={{ textDecoration: 'none' }}>
              <Fab
                sx={{
                  position: 'fixed',
                  bottom: 32,
                  right: 32,
                }}
                color="secondary"
                aria-label="add"
              >
                <AddIcon />
              </Fab>
            </Link>
          ) : (
            ''
          )}
        </Container>
      )}
    </>
  );
};

export default ItemsOverview;
