import { useAuth0 } from '@auth0/auth0-react';
import React, { createContext, useEffect, useState } from 'react';
import axios from '../api/axiosConfig';
import Spinner from '../components/common/Spinner';

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const { getAccessTokenSilently, isLoading, loginWithRedirect } = useAuth0();
  const [accessToken, setAccessToken] = useState('');

  useEffect(() => {
    const getToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        setAccessToken(token);
      } catch (error) {
        if (!isLoading && error) {
          loginWithRedirect();
        }
      }
    };

    getToken();
  }, [getAccessTokenSilently, isLoading, loginWithRedirect]);

  if (!accessToken) {
    return <Spinner />;
  }

  return (
    <AuthContext.Provider value={accessToken}>{children}</AuthContext.Provider>
  );
};
