import React, { useState } from 'react';
import {
  Button,
  TextField,
  ListItem,
  ListItemAvatar,
  Avatar,
  Switch,
  ListItemText,
  Grid,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { t } from '@lingui/macro';
import { Link } from 'react-router-dom';
import ReactLoading from 'react-loading';
import placeHolder from '../../../../static/assets/placeholder-image-square.jpg';
import { Trans } from '@lingui/react';
import { updateItem } from '../../../../api/itemsApi';
import * as _ from 'lodash';
import classes from './Item.module.css';

const debouncedChangeHandler = _.debounce(
  ({ _id, price, freeDelivery }) => {
    const updatedItemObject = {
      _id,
    };

    if (price !== undefined) {
      updatedItemObject['price'] = price;
      updateItem(updatedItemObject);
    }

    if (freeDelivery !== undefined) {
      updatedItemObject['freeDelivery'] = freeDelivery;
      updateItem(updatedItemObject);
    }
  },
  2000,
  {
    leading: false,
  }
);

const Item = (props) => {
  const { _id, price, freeDelivery } = props.data;
  const [isLoading, setIsLoading] = useState(false);
  const itemRoute = '/item/' + _id;
  const [localPrice, setLocalPrice] = useState(price);
  const [localFreeDelivery, setLocalFreeDelivery] = useState(freeDelivery);

  const changeHandler = (event) => {
    if (event.target.id === 'freeDelivery') {
      setLocalFreeDelivery((prev) => !prev);
      debouncedChangeHandler({ _id, freeDelivery: event.target.checked });
    } else {
      setLocalPrice(event.target.value);
      debouncedChangeHandler({ _id, price: event.target.value });
    }
  };

  const deleteHandler = async () => {
    setIsLoading(true);
    await props.deleteItemById(_id);
    setIsLoading(false);
  };

  return (
    <React.Fragment>
      <ListItem className={classes.listItem}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item sm={3} xs={6} style={{ display: 'inline-flex' }}>
            <ListItemAvatar className={classes.itemAvatar}>
              <Avatar>
                <img
                  src={
                    props.data.photos && props.data.photos.length > 0
                      ? `data:image/jpeg;base64,${props.data.photos[0]}`
                      : placeHolder
                  }
                  className={classes.thumbnail}
                  alt={props.data.imgAlt}
                ></img>
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              disableTypography
              primary={
                <Typography className={classes.liText}>
                  <span title={props.data.title}>{props.data.title}</span>
                </Typography>
              }
              secondary={props.data.price + ' KM'}
            />
          </Grid>
          <Grid item sm={3} xs={6} className={classes.inputGrid}>
            <TextField
              edge="end"
              id="price"
              value={localPrice}
              placeholder={t`item.price.textFieldPlaceholder`}
              onChange={changeHandler}
              variant="outlined"
              style={{
                marginRight: '20px',
                width: '80px',
              }}
              size="small"
            ></TextField>
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
            style={{
              display: 'inline-flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
            }}
          >
            <Switch
              className={classes.freeDelivery}
              edge="end"
              id="freeDelivery"
              onChange={changeHandler}
              checked={localFreeDelivery}
              inputProps={{ 'aria-labelledby': 'switch-list-label-delivery' }}
            />
            <Trans edge="end" id="item.freShipping.label" />
            <div>
              <Link
                to={itemRoute}
                style={{ textDecoration: 'none', color: 'black' }}
              >
                <Button
                  edge="end"
                  aria-label="add"
                  variant="text"
                  startIcon={<EditIcon></EditIcon>}
                  color="secondary"
                >
                  <Trans id="item.edit.button" />
                </Button>
              </Link>

              {isLoading ? (
                <Button edge="end" color="error" aria-label="delete">
                  <ReactLoading
                    type="bars"
                    color="black"
                    width={30}
                    height={30}
                  />
                </Button>
              ) : (
                <Button
                  onClick={deleteHandler}
                  variant="text"
                  startIcon={<DeleteIcon />}
                  color="primary"
                  edge="end"
                >
                  <Trans id="item.delete.button" />
                </Button>
              )}
            </div>
          </Grid>
        </Grid>
      </ListItem>
    </React.Fragment>
  );
};

export default Item;
