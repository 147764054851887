import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import { BrowserRouter, createBrowserHistory } from 'react-router-dom';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { Router } from 'react-router';
import { createBrowserHistory } from 'history';
import { Auth0Provider } from '@auth0/auth0-react';
import { ImportContextProvider } from './contexts/ImportContext';
import { AddItemContextProvider } from './contexts/AddItemContext';
import { messages } from './locales/bs/messages';
import development from './environments/development';
import production from './environments/production';
import { AuthContextProvider } from './contexts/AuthContext';
import './index.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';

i18n.load('bs', messages);
i18n.activate('bs');

export const browserHistory = createBrowserHistory();

export const environment =
  process.env.NODE_ENV === 'development' ? development : production;
const redirectUri = `${window.location.protocol}//${window.location.host}`;

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#3a3e3d',
    },
    secondary: {
      main: '#45B97C',
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <I18nProvider i18n={i18n}>
      <Router history={browserHistory}>
        <Auth0Provider
          domain={environment.domain}
          clientId={environment.clientId}
          redirectUri={redirectUri}
          audience={environment.audience}
          advancedOptions={{ defaultScope: 'openid profile email' }}
          useRefreshTokens={true}
        >
          <ImportContextProvider>
            <AddItemContextProvider>
              <ThemeProvider theme={theme}>
                <AuthContextProvider>
                  <App />
                </AuthContextProvider>
              </ThemeProvider>
            </AddItemContextProvider>
          </ImportContextProvider>
        </Auth0Provider>
      </Router>
    </I18nProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
