import React, { createContext, useState } from 'react';

export const AddItemContext = createContext();

export const AddItemContextProvider = ({ children }) => {
  const [data, setData] = useState([]);

  const store = {
    useItemData: [data, setData],
  };
  return (
    <AddItemContext.Provider value={store}>{children}</AddItemContext.Provider>
  );
};
