import React from 'react';
import ItemsOverview from './components/itemsOverviewPage/ItemsOverviewPage';
import { Route, Switch } from 'react-router-dom';
import ItemEdit from './components/ItemEdit/ItemEdit';
import Config from './components/config/Config';
import Import from './components/Import/Import';

const App = () => {

  return (
    <Switch>
      <Route path="/" exact>
        <ItemsOverview />
      </Route>
      <Route path="/item/:id">
        <ItemEdit />
      </Route>
      <Route path="/item" exact>
        <ItemEdit />
      </Route>
      <Route path="/import">
        <Import />
      </Route>
      <Route path="/config">
        <Config />
      </Route>
    </Switch>
  );
};

export default App;
