import React, { useState, useEffect } from 'react';
import { Trans } from '@lingui/react';
import {
  List,
  Dialog,
  DialogContentText,
  DialogActions,
  Button,
  DialogTitle,
  DialogContent,
  Pagination,
  Stack,
} from '@mui/material';
import { deleteItem } from '../../../api/itemsApi';
import ReactLoading from 'react-loading';
import { styled } from '@mui/material/styles';
import Item from './itemRow/Item';

export const ITEMS_PER_PAGE = 20;

const TablePaginationWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '50px',
}));

const ItemsList = (props) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [numberOfItems, setNumberOfItems] = useState(0);
  const [errorPopup, setErrorPopup] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [currentId, setCurrentId] = useState();
  const [confirmIsLoading, setConfirmIsLoading] = useState(false);

  useEffect(() => {
    if (props.data) {
      setData(props.data);
      setNumberOfItems(props.numberOfItems);
    }
  }, [props.data, props.numberOfItems]);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
    props.onPageChangeHandler && props.onPageChangeHandler(newPage);
  };

  const closeErrorPopup = () => {
    setErrorPopup(false);
  };

  const closeConfirmPopup = () => {
    setConfirmPopup(false);
  };

  const deleteItemById = async () => {
    setConfirmIsLoading(true);
    const _id = currentId;
    const result = await deleteItem(String(_id));

    if (!result._id) {
      setErrorPopup(true);
    } else {
      props.deleteDataHandler(_id);
    }
    setConfirmIsLoading(false);
    setConfirmPopup(false);
  };

  const getdeleteItemId = (_id) => {
    setConfirmPopup(true);
    setCurrentId(_id);
  };

  return (
    <>
      <List>
        <Dialog
          sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
          maxWidth="xs"
          open={confirmPopup}
        >
          <DialogTitle>
            <Trans id="ItemList.areYouShure.dialogTitle" />
          </DialogTitle>
          <DialogContent>
            <Trans id="ItemList.areYouShure.dialogContent" />
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={closeConfirmPopup}>
              <Trans id="ItemList.goBackConfirm.dialogActions" />
            </Button>

            <Button onClick={deleteItemById} style={{ color: 'red' }}>
              {confirmIsLoading ? (
                <ReactLoading type="bars" color="red" width={30} height={30} />
              ) : (
                <Trans id="ItemList.deleteItem.dialogActionsConfirm" />
              )}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={errorPopup}
          onClose={closeErrorPopup}
          aria-describedby="alert-dialog-slide-description"
          style={{ textAlign: 'center' }}
        >
          <DialogTitle>
            <Trans id="item.errorPopupTitle.dialogTitle" />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Trans id="item.errorPopupText.DialogContentText" />
            </DialogContentText>
          </DialogContent>
          <DialogActions
            style={{ textAlign: 'center', justifyContent: 'center' }}
          >
            <Button onClick={closeErrorPopup} id="pass">
              <Trans id="item.ok.dialogActions.Button" />
            </Button>
          </DialogActions>
        </Dialog>
        {data.map((row, index) => (
          <Item
            key={`${index}${row.title}`}
            data={row}
            deleteItemById={getdeleteItemId}
          ></Item>
        ))}
      </List>
      <TablePaginationWrapper>
        <Stack spacing={2}>
          <Pagination
            variant="outlined"
            shape="rounded"
            page={page}
            onChange={handleChangePage}
            count={Math.ceil(numberOfItems / ITEMS_PER_PAGE)}
          />
        </Stack>
      </TablePaginationWrapper>
    </>
  );
};

export default ItemsList;
