import axios from './axiosConfig';

export async function getConfig() {
  try {
    const resp = await axios.get('/config');
    return resp.data;
  } catch (err) {
    // Handle Error Here
    console.error(err);
  }
}

export async function updateConfig(item) {
  try {
    const resp = await axios.post('/config', {
      description: item.description,
      available: item.available,
    });
    return resp.data;
  } catch (err) {
    // Handle Error Here
    console.error(err);
    return err;
  }
}
