import React, { useState, useEffect, useContext } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useParams, useHistory } from 'react-router';
import { t } from '@lingui/macro';
import {
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Checkbox,
  FormControlLabel,
  Button,
  Grid,
  DialogContentText,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { Checkmark } from 'react-checkmark';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  getItem,
  addItem,
  updateItem,
  postImg,
  postImgArray,
  deleteImages,
} from '../../api/itemsApi';
import { getConfig } from '../../api/configApi';
import ImageUploader from 'react-images-upload';
import { Trans } from '@lingui/react';
import ReactLoading from 'react-loading';
import Navigation from '../navigation/Navigation';
import { ImportContext } from '../../contexts/ImportContext';
import { AddItemContext } from '../../contexts/AddItemContext';
import errorPng from '../../static/assets/error.png';
import classes from './ItemEdit.module.css';
import { brandOptions } from '../helpers/data/brandOptions';
import { contentOptions } from '../helpers/data/contentOptions';
import { typeOptions } from '../helpers/data/typeOptions';

const ItemEdit = () => {
  const [editingMode, setEditingMode] = useState(true);
  const [editedItem, setEditedItem] = useState({
    listingType: 'sell',
    freeDelivery: false,
    cityId: 79,
    categoryId: '',
    internalId: '0',
    barcode: '',
    title: '',
    url: 'undefined',
    price: '',
    state: 'new',
    content: '',
    type: '',
    brandId: '',
    photos: [],
    olxPhotoIds: [],
  });

  const [pictures, setPictures] = useState([]);
  const [error, setError] = useState({
    picturesError: false,
    type: false,
    content: false,
    brandId: false,
  });
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const { id: itemId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [updatePassed, setUpdatedPassed] = useState(false);
  const [hasConfigError, setHasConfigError] = useState(false);
  const [contentSelectedOption, setContentSelectedOption] = useState('');
  const [brandSelectedOption, setBrandSelectedOption] = useState('');
  const [typeSelectedOption, setTypeSelectedOption] = useState('');
  const { useItemData } = useContext(AddItemContext);
  const [newItemData, setNewItemData] = useItemData;
  const { importedItems } = useContext(ImportContext);
  const [importItemsArray, setImportedItemsArray] = importedItems;
  const history = useHistory();
  const urlParams = useLocation().state;

  useEffect(() => {
    if (editingMode) {
      setContentSelectedOption(editedItem.content);
      setTypeSelectedOption(editedItem.type);
      const selectedBrand = brandOptions.find(
        (brand) => brand.id === editedItem.brandId
      );
      if (selectedBrand) {
        setBrandSelectedOption(selectedBrand);
      }
    }
  }, [editingMode, editedItem.content, editedItem.type, editedItem.brandId]);

  useEffect(() => {
    const fetchData = async () => {
      if (itemId) {
        const item = await getItem(itemId);
        if (typeof item.__v !== 'undefined') {
          delete item.__v;
        }
        setEditedItem(item);
      } else {
        setEditingMode(false);
        let newItem = { ...editedItem };

        if (urlParams?.barcode) {
          newItem = { ...newItem, ...urlParams };
        }
        const configData = await getConfig();

        if (configData && typeof configData.__v !== 'undefined') {
          delete configData.__v;
        }
        if (configData?.description) {
          setEditedItem({ ...newItem, ...configData });
        } else {
          setHasConfigError(true);
        }
      }
    };
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeContextItem = (importItemsArray, barcode) => {
    const newImportItemsArray = importItemsArray.filter(
      (item) => item.barcode !== barcode
    );
    setImportedItemsArray(newImportItemsArray);
  };

  const handleConfigErrorClose = () => {
    setHasConfigError(false);
    history.goBack();
  };

  const handlePopupClose = (event) => {
    if (event.target.id === 'pass') {
      history.goBack();
    } else {
      setIsPopupOpen(false);
      setIsLoading(false);
    }
  };

  // NOT USED //
  // const notValidatedTextFieldHandler = (event) => {
  //   const newEditedItem = { ...editedItem };
  //   const value = event.target.value;

  //   newEditedItem[event.target.id] = value;
  //   setEditedItem({ ...newEditedItem });
  // };

  const textFieldHandler = (event) => {
    const newEditedItem = { ...editedItem };
    const newError = { ...error };
    const value = event.target.value;

    if (value) {
      newError[event.target.id] = false;
    } else {
      newError[event.target.id] = true;
    }

    if (event.target.id === 'title') {
      newError.title = !validateTitle(value);
    }

    if (event.target.id === 'price') {
      newEditedItem[event.target.id] = value;
    } else if (event.target.id === 'type') {
      newEditedItem[event.target.id] = value;
      newError.type = !value.trim();
    } else if (event.target.id === 'content') {
      newEditedItem[event.target.id] = value;
      newError.content = !value.trim();
    } else if (event.target.id === 'brandId') {
      newEditedItem[event.target.id] = value;
      newError.brandId = !value.trim();
    } else {
      newEditedItem[event.target.id] = value;
    }

    setEditedItem({ ...newEditedItem });
    setError(newError);

    const errorArray = Object.values(newError) || [];
    const hasError = errorArray.reduce((prev, curr) => prev || curr);
    setSubmitButtonDisabled(hasError);
  };

  const radioHandler = (event) => {
    setEditedItem({ ...editedItem, categoryId: event.target.value });
  };

  const handleContentPickerChange = (event) => {
    setContentSelectedOption(event.target.value);
    const newError = { ...error, content: false };
    setEditedItem({ ...editedItem, content: event.target.value });
    setError(newError);
    setSubmitButtonDisabled(false);
  };

  const handleTypePickerChange = (event) => {
    setTypeSelectedOption(event.target.value);
    const newError = { ...error, type: false };
    setEditedItem({ ...editedItem, type: event.target.value });
    setError(newError);
    setSubmitButtonDisabled(false);
  };

  const handleBrandPickerChange = (event) => {
    const selectedBrand = event.target.value;
    setBrandSelectedOption(selectedBrand);
    const newError = { ...error, brandId: false };
    setEditedItem({ ...editedItem, brandId: selectedBrand.id });
    setError(newError);
    setSubmitButtonDisabled(false);
  };

  const validateTitle = (value) => {
    const trimmedValue = value.trim();
    const latinScriptRegex =
      /^[a-zA-Z0-9\s!@?.<>,;:"'()[\]{}\-_=+*/\\|`~$%^&]*$/;

    const isValidLength = trimmedValue.length <= 55;
    const isValidFormat =
      trimmedValue.includes(' ') && trimmedValue.match(latinScriptRegex);

    return isValidLength && isValidFormat;
  };

  const freeDeliveryHandler = () => {
    setEditedItem({ ...editedItem, freeDelivery: !editedItem.freeDelivery });
    let newError = { ...error };
    newError = { ...newError, freeDelivery: false };
    setError(newError);
    const errorArray = Object.values(newError) || [];
    const hasError = errorArray.reduce((prev, curr) => prev || curr);
    setSubmitButtonDisabled(hasError);
  };

  const onDrop = (picture) => {
    setPictures([...picture]);
    let newError = { ...error };

    if (picture.length === 1) {
      postImg(picture[0]);
      newError = { ...newError, picturesError: false };
    } else if (picture.length > 1) {
      postImgArray(picture);
      newError = { ...newError, picturesError: false };
    } else if (editedItem.photos.length === 0) {
      newError = { ...newError, picturesError: true };
    } else {
      newError = { ...newError, picturesError: false };
    }

    setError(newError);
    setSubmitButtonDisabled(Object.values(newError).some((value) => value));
  };

  const photosDeleteHandler = async () => {
    setEditedItem((prevItem) => ({
      ...prevItem,
      photos: [],
      olxPhotoIds: [],
    }));

    await deleteImages(itemId);
  };

  const submitHandlerNew = async () => {
    const newError = { ...error };

    // Validate title
    newError.title = !validateTitle(editedItem.title);

    for (const item in editedItem) {
      if (
        item !== 'freeDelivery' &&
        item !== 'available' &&
        !editedItem[item]
      ) {
        newError[item] = true;
      }
    }

    if (pictures.length !== 0) {
      newError.picturesError = false;
    } else {
      newError.picturesError = true;
    }

    setError(newError);

    const errorArray = Object.values(newError) || [];
    const hasError = errorArray.reduce((prev, curr) => prev || curr);

    if (!hasError) {
      setIsLoading(true);

      try {
        const response = await addItem(editedItem);

        if (response._id) {
          setUpdatedPassed(true);
          setNewItemData([...newItemData, editedItem]);
          removeContextItem(importItemsArray, editedItem.barcode);
          // setRedirect(!redirect);
          history.goBack();
        } else {
          setUpdatedPassed(false);
          setIsPopupOpen(true);
        }
      } catch (error) {
        console.error('An error occurred:', error);
        setUpdatedPassed(false);
        setIsPopupOpen(true);
      }
    } else {
      setSubmitButtonDisabled(hasError);
    }
  };

  const submitHandler = async () => {
    const newError = { ...error };

    for (const item in editedItem) {
      if (
        item !== 'freeDelivery' &&
        item !== 'available' &&
        !editedItem[item]
      ) {
        newError[item] = true;
      }
    }

    const errorArray = Object.values(newError) || [];
    const hasError = errorArray.reduce((prev, curr) => prev || curr);

    if (editedItem.photos.length < 1 && pictures.length < 1) {
      newError.picturesError = true;
    } else {
      newError.picturesError = false;
    }

    setError(newError);

    if (newError.picturesError === true) {
      setSubmitButtonDisabled(true);
      return;
    }

    if (!hasError) {
      setIsLoading(true);

      updateItem(editedItem).then((response) => {
        let pass;

        if (response && response._id) {
          pass = true;
        } else {
          setUpdatedPassed(false);
          setIsPopupOpen(true);
        }

        if (pass === true) {
          setUpdatedPassed(true);
          // setRedirect(!redirect);
          history.goBack();
        }
      });
    } else {
      setSubmitButtonDisabled(hasError);
    }
  };

  return (
    <React.Fragment>
      <Navigation
        title={
          editingMode ? (
            <Trans id="itemEdit.editArticle.header" />
          ) : (
            <Trans id="itemEdit.addArticle.header" />
          )
        }
      />
      <Container maxWidth="md" className={classes.container}>
        <Dialog
          open={hasConfigError}
          onClose={handleConfigErrorClose}
          aria-describedby="alert-dialog-slide-description"
          style={{ textAlign: 'center' }}
        >
          <DialogTitle>
            <Trans id="itemEdit.configError.dialogTitle" />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <br></br>
              <img src={errorPng} alt="errorPng" />
              <br></br>
              <Trans id="itemEdit.configError.dialogContentText" />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Link
              to="/config"
              style={{ textDecoration: 'none', color: 'black' }}
            >
              <Button id="configLink">
                <Trans id="itemEdit.editConfig.dialogButton" />
              </Button>
            </Link>

            <Button
              onClick={handleConfigErrorClose}
              id="back"
              style={{ color: 'red ' }}
            >
              <Trans id="itemEdit.back.dialButton" />
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={isPopupOpen}
          onClose={handlePopupClose}
          aria-describedby="alert-dialog-slide-description"
          style={{ textAlign: 'center' }}
        >
          <DialogTitle>
            {editingMode ? (
              <Trans id="itemEdit.changingItem.dialogTitle" />
            ) : (
              <Trans id="itemEdit.addingItem.dialogTitle" />
            )}
          </DialogTitle>
          <DialogContent>
            {updatePassed ? (
              <DialogContentText>
                <br></br>
                <Checkmark />
                <br></br>
                {editingMode ? (
                  <Trans id="itemEdit.itemChanged.dialogConentText" />
                ) : (
                  <Trans id="itemEdit.itemAdded.dialogContentText" />
                )}
              </DialogContentText>
            ) : (
              <DialogContentText>
                <br></br>
                <img src={errorPng} alt="errorPng" />
                <br></br>
                <Trans id="itemEdit.requestRejected.dialogContentText" />
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handlePopupClose} id="pass">
              <Trans id="itemEdit.accept.dialogButton" />
            </Button>
            <Button
              onClick={handlePopupClose}
              id="back"
              style={{ color: 'red ' }}
            >
              <Trans id="itemEdit.decline.dialButton" />
            </Button>
          </DialogActions>
        </Dialog>
        <Accordion defaultExpanded={true} expanded={true}>
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            <Typography>
              <Trans id="itemEdit.basicInformation.accordionHeader" />
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                {editingMode ? (
                  <div className={classes.divCenter}>
                    <TextField
                      className={classes.textFieldmargin}
                      value={editedItem.internalId || ''}
                      onChange={textFieldHandler}
                      type="number"
                      id="internalId"
                      label="ID"
                      variant="outlined"
                      error={error.internalId}
                      helperText={
                        error.internalId ? (
                          <Trans id="itemEdit.idError.helperText" />
                        ) : (
                          ''
                        )
                      }
                    ></TextField>
                  </div>
                ) : (
                  ''
                )}

                <div className={classes.divCenter}>
                  <TextField
                    className={classes.textFieldmargin}
                    value={editedItem.barcode || ''}
                    onChange={textFieldHandler}
                    id="barcode"
                    label={<Trans id="itemEdit.barcode.label" />}
                    variant="outlined"
                    type="text"
                    error={error.barcode}
                    helperText={
                      error.barcode ? (
                        <Trans id="itemEdit.barcodeError.helperText" />
                      ) : (
                        ''
                      )
                    }
                  ></TextField>
                </div>

                <div className={classes.divCenter}>
                  <TextField
                    className={classes.textFieldmargin}
                    value={editedItem.title || ''}
                    onChange={textFieldHandler}
                    type="text"
                    id="title"
                    label={<Trans id="itemEdit.name.label" />}
                    variant="outlined"
                    error={error.title}
                    helperText={
                      error.title ? (
                        <Trans id="itemEdit.itemNameError.helperText" />
                      ) : (
                        ''
                      )
                    }
                  ></TextField>
                </div>

                <div className={classes.divCenter}>
                  <FormControl fullWidth>
                    <InputLabel id="type-select-label">
                      <Trans id="itemEdit.brand.label" />
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={brandSelectedOption}
                      label={<Trans id="itemEdit.brand.label" />}
                      onChange={handleBrandPickerChange}
                      disabled={editingMode}
                      error={error.brandId}
                    >
                      {brandOptions.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className={classes.divCenter}>
                  <FormControl fullWidth>
                    <InputLabel id="type-select-label">
                      <Trans id="itemEdit.content.label" />
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={contentSelectedOption}
                      label={<Trans id="itemEdit.content.label" />}
                      onChange={handleContentPickerChange}
                      error={error.content}
                    >
                      {contentOptions.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className={classes.divCenter}>
                  <FormControl fullWidth>
                    <InputLabel id="type-select-label">
                      <Trans id="itemEdit.type.label" />
                    </InputLabel>
                    <Select
                      labelId="type-select-label"
                      id="type-select"
                      value={typeSelectedOption}
                      label={<Trans id="itemEdit.type.label" />}
                      onChange={handleTypePickerChange}
                      error={error.type}
                    >
                      {typeOptions.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className={classes.divCenter}>
                  <TextField
                    value={editedItem.price || ''}
                    onChange={textFieldHandler}
                    className={classes.textFieldmargin}
                    type="number"
                    id="price"
                    label={<Trans id="itemEdit.price.label" />}
                    variant="outlined"
                    error={error.price}
                    helperText={
                      error.price ? (
                        <Trans id="itemEdit.priceError.helperText" />
                      ) : (
                        <Trans id="itemEdit.onlyInKM.helperText" />
                      )
                    }
                  ></TextField>
                </div>

                <div className={classes.divCenter}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      <Trans id="editItem.category.formLabel" />
                    </FormLabel>
                    <RadioGroup
                      row
                      id="categoryId"
                      aria-label="gender"
                      name="row-radio-buttons-group"
                      onChange={radioHandler}
                      value={editedItem.categoryId}
                    >
                      <FormControlLabel
                        value="1371"
                        control={<Radio />}
                        label={<Trans id="itemEdit.female.radio" />}
                        disabled={editingMode}
                      />

                      <FormControlLabel
                        value="1370"
                        control={<Radio />}
                        label={<Trans id="itemEdit.male.radio" />}
                        disabled={editingMode}
                      />

                      <FormControlLabel
                        value="1372"
                        control={<Radio />}
                        label="Unisex"
                        disabled={editingMode}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </Grid>

              <Grid item xs={12} md={7}>
                {editedItem?.photos.length > 0
                  ? editedItem.photos.map((base64, id) => {
                      return (
                        <div
                          key={id}
                          className={classes.imgWrapper}
                          style={{ marginBottom: 10 }}
                        >
                          <img
                            src={`data:image/jpeg;base64,${base64}`}
                            alt="pic"
                            className={classes.previewPic}
                          />
                        </div>
                      );
                    })
                  : null}
                {editedItem.photos.length > 0 && (
                  <button
                    className={classes.deletePic}
                    onClick={photosDeleteHandler}
                    style={{ top: 40, right: '10%' }}
                  >
                    X
                  </button>
                )}

                <div>
                  <ImageUploader withIcon withPreview onChange={onDrop} />
                  {error.picturesError ? (
                    <Typography
                      style={{
                        color: 'red',
                        borderBottom: 'solid',
                        borderWidth: '1px',
                      }}
                    >
                      <Trans id="itemEdit.picturesError.error" />
                    </Typography>
                  ) : (
                    ''
                  )}
                </div>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            aria-controls="panel2a-content"
            id="panel2a-header"
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography>
              <Trans id="itemEdit.other.accordionHeader2" />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {editingMode ? (
              <div className={classes.divCenter}>
                <TextField
                  value={editedItem.url || ''}
                  onChange={textFieldHandler}
                  type="text"
                  id="url"
                  label={<Trans id="itemEdit.link.label" />}
                  variant="outlined"
                  error={error.url}
                  helperText={
                    error.url ? <Trans id="itemEdit.urlError.helperText" /> : ''
                  }
                />
              </div>
            ) : (
              ''
            )}

            <div className={classes.divCenter}>
              <FormControlLabel
                control={
                  <Checkbox
                    label={<Trans id="itemEdit.freeShipping.label" />}
                    checked={editedItem.freeDelivery}
                    onChange={freeDeliveryHandler}
                    disabled={!editingMode}
                  />
                }
                label={<Trans id="itemEdit.freeShipping.formControlLabel" />}
              ></FormControlLabel>
            </div>
            <div className={classes.divCenter}>
              <TextField
                value={editedItem.description || ''}
                onChange={textFieldHandler}
                type="text"
                id="description"
                label={<Trans id="itemEdit.description.label" />}
                variant="outlined"
                multiline
                rows={5}
                fullWidth
              />
            </div>
          </AccordionDetails>
        </Accordion>
        <div className={classes.submitButton}>
          <Button
            onClick={editingMode ? submitHandler : submitHandlerNew}
            variant="outlined"
            type="submit"
            color="secondary"
            className={classes.buttonMargin}
            disabled={submitButtonDisabled}
          >
            {editingMode ? (
              isLoading ? (
                <ReactLoading
                  type="bars"
                  color="#7ac142"
                  width={20}
                  height={20}
                />
              ) : (
                <Trans id="itemEdit.editItem.button" />
              )
            ) : isLoading ? (
              <ReactLoading
                type="bars"
                color="#7ac142"
                width={20}
                height={20}
              />
            ) : (
              <Trans id="itemEdit.submitChanges.button" />
            )}
          </Button>
          <Button variant="outlined" color="error" onClick={history.goBack}>
            <Trans id="itemEdit.return.button" />
          </Button>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default ItemEdit;
