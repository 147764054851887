export const brandOptions = [
  {
    id: 3259,
    name: '4711',
  },
  {
    id: 3260,
    name: 'Aayan Perfume',
  },
  {
    id: 120,
    name: 'Adidas',
  },
  {
    id: 3278,
    name: 'Adopt’',
  },
  {
    id: 3277,
    name: 'Aedes de Venustas',
  },
  {
    id: 3261,
    name: 'Afnan',
  },
  {
    id: 3262,
    name: 'Ajmal',
  },
  {
    id: 3275,
    name: 'Alexandre.J',
  },
  {
    id: 3272,
    name: 'Al Fares',
  },
  {
    id: 2149,
    name: 'Alfred Dunhill',
  },
  {
    id: 3263,
    name: 'Al Haramain',
  },
  {
    id: 3280,
    name: 'alverde NATURKOSMETIK',
  },
  {
    id: 3264,
    name: 'Al Wataniah',
  },
  {
    id: 3265,
    name: 'Amouage',
  },
  {
    id: 3274,
    name: 'Angel Schlesser',
  },
  {
    id: 3279,
    name: 'Antonio Banderas',
  },
  {
    id: 3273,
    name: 'Arcadia',
  },
  {
    id: 3271,
    name: 'Arden',
  },
  {
    id: 3266,
    name: 'Ariana Grande',
  },
  {
    id: 3267,
    name: 'Armaf',
  },
  {
    id: 3268,
    name: 'Armand Basi',
  },
  {
    id: 3276,
    name: 'Armani Privé',
  },
  {
    id: 3270,
    name: 'Atelier Des Ors',
  },
  {
    id: 729,
    name: 'Avon',
  },
  {
    id: 3269,
    name: 'Avril Lavigne',
  },
  {
    id: 2170,
    name: 'Azzaro',
  },
  {
    id: 3287,
    name: 'Banana Republic',
  },
  {
    id: 3288,
    name: 'Bath & Body Works',
  },
  {
    id: 3289,
    name: 'Bebe',
  },
  {
    id: 3300,
    name: 'Bellagio',
  },
  {
    id: 3296,
    name: 'Betty Barclay',
  },
  {
    id: 3290,
    name: 'Betty Boop',
  },
  {
    id: 3291,
    name: 'Beyonce',
  },
  {
    id: 3297,
    name: 'Bi - Es',
  },
  {
    id: 3284,
    name: 'Blumarine',
  },
  {
    id: 3285,
    name: 'Bois 1920',
  },
  {
    id: 3283,
    name: 'Bond No. 9',
  },
  {
    id: 539,
    name: 'Boss',
  },
  {
    id: 3282,
    name: 'Bottega Veneta',
  },
  {
    id: 2194,
    name: 'Boucheron',
  },
  {
    id: 3298,
    name: 'Bourjois',
  },
  {
    id: 3292,
    name: 'Britney Spears',
  },
  {
    id: 3286,
    name: 'Brocard',
  },
  {
    id: 3293,
    name: 'Bruno Banani',
  },
  {
    id: 3299,
    name: 'B.U.',
  },
  {
    id: 14,
    name: 'Bugatti',
  },
  {
    id: 342,
    name: 'Burberry',
  },
  {
    id: 572,
    name: 'Bvlgari',
  },
  {
    id: 3294,
    name: 'Byblos',
  },
  {
    id: 3295,
    name: 'Byredo',
  },
  {
    id: 3301,
    name: 'Cacharel',
  },
  {
    id: 354,
    name: 'Calvin Klein',
  },
  {
    id: 471,
    name: 'Carolina Herrera',
  },
  {
    id: 3302,
    name: 'Caron',
  },
  {
    id: 576,
    name: 'Cartier',
  },
  {
    id: 3307,
    name: 'Casamorati',
  },
  {
    id: 2218,
    name: 'Cerruti',
  },
  {
    id: 552,
    name: 'Chanel',
  },
  {
    id: 3303,
    name: 'Chloé',
  },
  {
    id: 2253,
    name: 'Chopard',
  },
  {
    id: 3310,
    name: 'Christina Aguilera',
  },
  {
    id: 3309,
    name: 'Clive Christian',
  },
  {
    id: 3304,
    name: 'Coach',
  },
  {
    id: 3308,
    name: 'Cosmogony',
  },
  {
    id: 3306,
    name: 'CoSTUME NATIONAL',
  },
  {
    id: 3305,
    name: 'Creed',
  },
  {
    id: 3311,
    name: 'Cuba',
  },
  {
    id: 3312,
    name: 'David Beckham',
  },
  {
    id: 544,
    name: 'Davidoff',
  },
  {
    id: 3281,
    name: 'Debut Etienne Aigner',
  },
  {
    id: 650,
    name: 'Denim',
  },
  {
    id: 3313,
    name: 'Desigual',
  },
  {
    id: 3314,
    name: 'Diana Ross',
  },
  {
    id: 434,
    name: 'Diesel',
  },
  {
    id: 654,
    name: 'Dior',
  },
  {
    id: 3316,
    name: 'DKNY',
  },
  {
    id: 2148,
    name: 'Dolce & Gabbana',
  },
  {
    id: 3315,
    name: 'Dolce&Mania',
  },
  {
    id: 3189,
    name: 'Dsquared2',
  },
  {
    id: 3327,
    name: 'Eight & Bob',
  },
  {
    id: 3317,
    name: 'Elie Saab',
  },
  {
    id: 3323,
    name: 'Elizabeth Arden',
  },
  {
    id: 3328,
    name: 'Elode',
  },
  {
    id: 3321,
    name: 'Emper',
  },
  {
    id: 3322,
    name: 'Emporium',
  },
  {
    id: 3324,
    name: 'Escada',
  },
  {
    id: 3318,
    name: 'Escentric Molecules',
  },
  {
    id: 409,
    name: 'Esprit',
  },
  {
    id: 3325,
    name: 'Essenza',
  },
  {
    id: 3326,
    name: 'Estee Lauder',
  },
  {
    id: 3319,
    name: "Etat Libre d'Orange",
  },
  {
    id: 3320,
    name: 'Etro',
  },
  {
    id: 2303,
    name: 'Fendi',
  },
  {
    id: 3329,
    name: 'Ferragamo',
  },
  {
    id: 28,
    name: 'Ferrari',
  },
  {
    id: 3330,
    name: 'Flavia',
  },
  {
    id: 3333,
    name: 'Flor De Mayo',
  },
  {
    id: 3336,
    name: 'Fragonard',
  },
  {
    id: 3334,
    name: 'Franck Boclet',
  },
  {
    id: 3335,
    name: 'Franck Olivier',
  },
  {
    id: 3331,
    name: 'Frederic Malle',
  },
  {
    id: 3332,
    name: 'Furla',
  },
  {
    id: 3337,
    name: 'Gabriela Sabatini',
  },
  {
    id: 3343,
    name: 'Gianfranco Ferrè',
  },
  {
    id: 3338,
    name: 'Giardino Benessere',
  },
  {
    id: 3342,
    name: 'Giardino dei Sensi',
  },
  {
    id: 581,
    name: 'Giorgio Armani',
  },
  {
    id: 3344,
    name: 'Gisada',
  },
  {
    id: 588,
    name: 'Givenchy',
  },
  {
    id: 3339,
    name: 'Goutal',
  },
  {
    id: 3341,
    name: 'Grandeur Elite',
  },
  {
    id: 504,
    name: 'Gucci',
  },
  {
    id: 3340,
    name: 'Guerlain',
  },
  {
    id: 289,
    name: 'Guess',
  },
  {
    id: 725,
    name: 'Hermes',
  },
  {
    id: 3346,
    name: 'Histoires de Parfums',
  },
  {
    id: 3345,
    name: 'Houbigant',
  },
  {
    id: 234,
    name: 'Hugo Boss',
  },
  {
    id: 3348,
    name: 'Iceberg',
  },
  {
    id: 3349,
    name: 'Initio Parfums Privés',
  },
  {
    id: 3347,
    name: 'Issey Miyake',
  },
  {
    id: 39,
    name: 'Jaguar',
  },
  {
    id: 3360,
    name: 'James Bond',
  },
  {
    id: 3361,
    name: 'Janan',
  },
  {
    id: 627,
    name: 'Jean Paul Gaultier',
  },
  {
    id: 3362,
    name: 'Jennifer Lopez',
  },
  {
    id: 3358,
    name: 'Jil Sander',
  },
  {
    id: 3356,
    name: 'Jimmy Choo',
  },
  {
    id: 3359,
    name: 'Jo Malone',
  },
  {
    id: 324,
    name: 'Joop',
  },
  {
    id: 3357,
    name: 'Juliette has a gun',
  },
  {
    id: 3363,
    name: 'Kajal',
  },
  {
    id: 3061,
    name: 'Karl Lagerfeld',
  },
  {
    id: 665,
    name: 'Kenzo',
  },
  {
    id: 3364,
    name: 'Kilian Paris',
  },
  {
    id: 3365,
    name: 'Kim Kardashian',
  },
  {
    id: 3368,
    name: 'Laboratorio Olfattivo',
  },
  {
    id: 584,
    name: 'Lacoste',
  },
  {
    id: 3376,
    name: 'Lalique',
  },
  {
    id: 44,
    name: 'Lamborghini',
  },
  {
    id: 2402,
    name: 'Lanco',
  },
  {
    id: 800,
    name: 'Lancome',
  },
  {
    id: 3373,
    name: 'La Rive',
  },
  {
    id: 3371,
    name: "L'Artisan Parfumeur",
  },
  {
    id: 3367,
    name: 'Lattafa',
  },
  {
    id: 3366,
    name: 'Laura Biagiotti',
  },
  {
    id: 3374,
    name: 'Laura Mercier',
  },
  {
    id: 3369,
    name: 'Le Chameau',
  },
  {
    id: 3377,
    name: 'Liu Jo',
  },
  {
    id: 3372,
    name: "L'Occitane",
  },
  {
    id: 3375,
    name: 'Loewe',
  },
  {
    id: 3370,
    name: 'Lolita Lempicka',
  },
  {
    id: 3393,
    name: 'MAD Parfumeur',
  },
  {
    id: 3389,
    name: 'Maison Crivelli',
  },
  {
    id: 3384,
    name: 'Maison Francis Kurkdjian',
  },
  {
    id: 3385,
    name: 'Maison Margiela',
  },
  {
    id: 3386,
    name: 'Maison Trudon',
  },
  {
    id: 3381,
    name: 'Mancera',
  },
  {
    id: 3378,
    name: 'Mandarina Duck',
  },
  {
    id: 3387,
    name: 'Marc Jacobs',
  },
  {
    id: 3388,
    name: 'Matiere Premiere',
  },
  {
    id: 3391,
    name: 'MCM',
  },
  {
    id: 3396,
    name: 'MDCI Parfums',
  },
  {
    id: 3394,
    name: 'Memo Paris',
  },
  {
    id: 589,
    name: 'Mexx',
  },
  {
    id: 694,
    name: 'Michael Kors',
  },
  {
    id: 3390,
    name: 'MiN New York',
  },
  {
    id: 3392,
    name: 'Miss Kay',
  },
  {
    id: 3383,
    name: 'Mizensir Genève',
  },
  {
    id: 3380,
    name: 'M. Micallef',
  },
  {
    id: 3382,
    name: 'Montale',
  },
  {
    id: 3379,
    name: 'Mont Blanc',
  },
  {
    id: 925,
    name: 'Moschino',
  },
  {
    id: 3395,
    name: 'Moschus',
  },
  {
    id: 3401,
    name: 'Naf Naf',
  },
  {
    id: 3402,
    name: 'Naj Oleari',
  },
  {
    id: 3403,
    name: 'Naomi Campbell',
  },
  {
    id: 3397,
    name: 'Narciso Rodriguez',
  },
  {
    id: 3399,
    name: 'Nasomatto',
  },
  {
    id: 199,
    name: 'Nautica',
  },
  {
    id: 144,
    name: 'Nike',
  },
  {
    id: 3400,
    name: 'Nikos Sculpture',
  },
  {
    id: 2505,
    name: 'Nina Ricci',
  },
  {
    id: 3404,
    name: 'Nine West',
  },
  {
    id: 3398,
    name: 'Nishane Perfumes',
  },
  {
    id: 3354,
    name: 'Oak',
  },
  {
    id: 3352,
    name: 'Ojar',
  },
  {
    id: 3351,
    name: 'Olfactive Studio',
  },
  {
    id: 3353,
    name: 'Olfattology',
  },
  {
    id: 386,
    name: 'Oriflame',
  },
  {
    id: 3355,
    name: 'Ormonde Jayne',
  },
  {
    id: 3350,
    name: 'Orto Parisi',
  },
  {
    id: 556,
    name: 'Paco Rabanne',
  },
  {
    id: 3414,
    name: 'Paloma Picasso',
  },
  {
    id: 3407,
    name: 'Parfums de Marly',
  },
  {
    id: 3411,
    name: 'Pepe Jeans',
  },
  {
    id: 3405,
    name: 'Perris Monte Carlo',
  },
  {
    id: 3181,
    name: 'Philipp Plein',
  },
  {
    id: 3409,
    name: 'Philosophy Perfume',
  },
  {
    id: 2527,
    name: 'Pierre Balmain',
  },
  {
    id: 3413,
    name: 'Pino Silvestre',
  },
  {
    id: 1046,
    name: 'Playboy',
  },
  {
    id: 830,
    name: 'Police',
  },
  {
    id: 610,
    name: 'Prada',
  },
  {
    id: 3412,
    name: 'Prestige',
  },
  {
    id: 3408,
    name: 'Profumo di Firenze',
  },
  {
    id: 3410,
    name: 'PUPA Milano',
  },
  {
    id: 3406,
    name: 'Puredistance',
  },
  {
    id: 3415,
    name: 'Queen Latifah',
  },
  {
    id: 298,
    name: 'Ralph Lauren',
  },
  {
    id: 3416,
    name: 'Ramon Monegal',
  },
  {
    id: 3418,
    name: 'Rasasi',
  },
  {
    id: 861,
    name: 'Replay',
  },
  {
    id: 3419,
    name: 'Rihanna',
  },
  {
    id: 611,
    name: 'Roberto Cavalli',
  },
  {
    id: 3420,
    name: 'Roccobarocco',
  },
  {
    id: 3417,
    name: 'Roja Parfums',
  },
  {
    id: 3421,
    name: 'Route 66',
  },
  {
    id: 2581,
    name: 'Salvatore Ferragamo',
  },
  {
    id: 3427,
    name: 'Sarah Jessica Parker',
  },
  {
    id: 3428,
    name: 'Scentology',
  },
  {
    id: 3429,
    name: 'SEINZ.',
  },
  {
    id: 322,
    name: 'Sergio Tacchini',
  },
  {
    id: 3455,
    name: 'Shiseido',
  },
  {
    id: 3425,
    name: 'Shisheido',
  },
  {
    id: 3422,
    name: "Signature Sillage d'Orient",
  },
  {
    id: 3431,
    name: 'Simone Andreoli',
  },
  {
    id: 853,
    name: 'Sisley',
  },
  {
    id: 531,
    name: 'S.Oliver',
  },
  {
    id: 3432,
    name: 'Sospiro Perfumes',
  },
  {
    id: 3424,
    name: 'Stephane Humbert Lucas',
  },
  {
    id: 3430,
    name: 'STR8',
  },
  {
    id: 3423,
    name: 'Swiss Arabian',
  },
  {
    id: 1076,
    name: 'Swiss Army',
  },
  {
    id: 3426,
    name: 'Swiss Perfume',
  },
  {
    id: 3440,
    name: 'Tahari Parfums',
  },
  {
    id: 3456,
    name: 'Ted Lapidus',
  },
  {
    id: 3441,
    name: "Tesori d'Oriente",
  },
  {
    id: 3436,
    name: 'The Spirit of Dubai',
  },
  {
    id: 3438,
    name: 'The Woods Collection',
  },
  {
    id: 891,
    name: 'Thierry Mugler',
  },
  {
    id: 3437,
    name: 'Thomas Kosmala',
  },
  {
    id: 3435,
    name: 'Tiziana Terenzi',
  },
  {
    id: 3433,
    name: 'Tom Ford',
  },
  {
    id: 421,
    name: 'Tom Tailor',
  },
  {
    id: 3442,
    name: 'Tosca',
  },
  {
    id: 3439,
    name: 'Tree Of Life',
  },
  {
    id: 3434,
    name: 'Trussardi',
  },
  {
    id: 3443,
    name: 'UDV Ulric de Varens',
  },
  {
    id: 591,
    name: 'United Colors of Benetton',
  },
  {
    id: 2633,
    name: 'Valentino',
  },
  {
    id: 2639,
    name: 'Van Cleef & Arpels',
  },
  {
    id: 3447,
    name: 'Vanderbilt',
  },
  {
    id: 3444,
    name: 'V Canto',
  },
  {
    id: 542,
    name: 'Versace',
  },
  {
    id: 3446,
    name: 'Vertus',
  },
  {
    id: 3445,
    name: 'Viktor&Rolf',
  },
  {
    id: 3448,
    name: "Women'secret",
  },
  {
    id: 3449,
    name: 'Xerjoff',
  },
  {
    id: 3450,
    name: 'XYZ Perfume',
  },
  {
    id: 3453,
    name: 'Yaemina',
  },
  {
    id: 3451,
    name: 'Yves Rocher',
  },
  {
    id: 394,
    name: 'Yves Saint Laurent',
  },
  {
    id: 3452,
    name: 'YZY Perfumes',
  },
  {
    id: 3454,
    name: 'Zadig & Voltaire',
  },
  {
    id: 147,
    name: 'Zara',
  },
];
