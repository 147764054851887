import React, { useEffect, useState } from 'react';
import {
  Container,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { Checkmark } from 'react-checkmark';
import { Trans } from '@lingui/react';
import ReactLoading from 'react-loading';
import Navigation from '../navigation/Navigation';
import { getConfig, updateConfig } from '../../api/configApi';
import errorPng from '../../static/assets/error.png';
import classes from './Config.module.css';

const Config = () => {
  const [configuration, setConfiguration] = useState({
    available: false,
  });
  const [configurationError, setConfigurationError] = useState({});
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [updatePassed, setUpdatePassed] = useState(false);

  const handlePopupClose = (event) => {
    if (event.target.id === 'pass') {
      setRedirect(!redirect);
    } else {
      setOpenPopup(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const configData = await getConfig();

        if (configData) {
          const cleanedConfigData = { ...configData };
          delete cleanedConfigData.__v;
          delete cleanedConfigData._id;
          setConfiguration(cleanedConfigData);
        }
      } catch (error) {
        console.error('Error fetching configuration:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const isConfigEmpty = Object.keys(configuration).length === 0;
    const errorArray = Object.values(configurationError) || [];
    const hasConfigurationError = errorArray.reduce(
      (prev, curr) => prev || curr,
      false
    );

    const isDescriptionEmpty = !configuration.description;

    if (isConfigEmpty || isDescriptionEmpty) {
      setSubmitButtonDisabled(true);
    } else {
      setSubmitButtonDisabled(hasConfigurationError);
    }
  }, [configuration, configurationError]);

  const textFieldHandler = (event) => {
    const newConfiguration = { ...configuration };
    const newConfigurationError = { ...configurationError };
    const value = event.target.value;

    if (value) {
      newConfigurationError[event.target.id] = false;
    } else {
      newConfigurationError[event.target.id] = true;
    }

    newConfiguration[event.target.id] = value;
    setConfiguration({ ...newConfiguration });
    setConfigurationError(newConfigurationError);
  };

  const checkBoxHandler = (e) => {
    const newConfig = { ...configuration };
    const target = e.target.id;
    newConfig[target] = !newConfig[target];
    setConfiguration(newConfig);
  };

  const submitHandler = () => {
    setIsLoading(true);
    const newError = { ...configurationError };
    for (const item in configuration) {
      if (item !== 'available' && !configuration[item]) {
        newError[item] = true;
      } else {
        newError[item] = false;
      }
    }
    setConfigurationError(newError);

    const errorArray = Object.values(newError) || [];
    const hasError = errorArray.reduce((prev, curr) => prev || curr);

    if (!hasError) {
      let pass;
      updateConfig(configuration).then((response) => {
        if (response._id) {
          pass = true;
        } else {
          pass = false;
          setOpenPopup(true);
          setUpdatePassed(pass);
        }
        if (pass === true) {
          setRedirect(true);
        }
      });
    } else {
      setSubmitButtonDisabled(hasError);
    }
  };

  return (
    <React.Fragment>
      <Navigation title={<Trans id="config.generalConfig.header" />} />
      <Container maxWidth="md" className={classes.container}>
        {redirect && <Redirect to="/" />}
        <Dialog
          open={openPopup}
          onClose={handlePopupClose}
          aria-describedby="alert-dialog-slide-description"
          style={{ textAlign: 'center' }}
        >
          <DialogTitle>
            {<Trans id="config.settingsUpdate.dialogTitle" />}
          </DialogTitle>
          <DialogContent>
            {updatePassed ? (
              <DialogContentText>
                <br></br>
                <Checkmark className={classes.updateContentText} />
                <br></br>
                <Trans id="config.updatePassed.dialogConentText" />
              </DialogContentText>
            ) : (
              <DialogContentText>
                <br></br>
                <img
                  src={errorPng}
                  alt="errorPng"
                  className={classes.updateContentText}
                />
                <br></br>
                <Trans id="config.updateFailed.dialogContentText" />
              </DialogContentText>
            )}
          </DialogContent>

          <DialogActions>
            <Button onClick={handlePopupClose} id="pass">
              <Trans id="config.accept.dialogButton" />
            </Button>
            <Button
              onClick={handlePopupClose}
              id="back"
              style={{ color: 'red ' }}
            >
              <Trans id="config.decline.dialButton" />
            </Button>
          </DialogActions>
        </Dialog>

        <Grid container>
          <Grid item xs={12}>
            <div className={classes.divText}>
              <TextField
                id="description"
                className={classes.TextField}
                variant="outlined"
                value={configuration.description || ''}
                onChange={textFieldHandler}
                label={<Trans id="config.description.label" />}
                multiline
                rows={8}
              ></TextField>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.divText}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="available"
                    checked={configuration.available || false}
                    onChange={checkBoxHandler}
                  />
                }
                label={<Trans id="config.availability.label" />}
              ></FormControlLabel>
            </div>
          </Grid>
        </Grid>

        <div className={classes.buttonGroup}>
          <Button
            type="submit"
            onClick={submitHandler}
            color="secondary"
            variant="outlined"
            disabled={submitButtonDisabled}
          >
            {isLoading ? (
              <ReactLoading type="bars" color="white" width={20} height={20} />
            ) : (
              <Trans id="config.saveChanges.button" />
            )}
          </Button>
          <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>
            <Button color="error" variant="outlined">
              <Trans id="config.back.button" />
            </Button>
          </Link>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default Config;
