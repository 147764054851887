import React, {
  useState,
  useContext,
  useEffect,
  Fragment,
  useRef,
} from 'react';
import { Container, Button, List, Pagination, Stack } from '@mui/material';
import ImportItem from './ImportItem';
import { Trans } from '@lingui/react';
import { styled } from '@mui/material/styles';
import Navigation from '../navigation/Navigation';
import { CircularProgress } from '@mui/material';
import { ImportContext } from '../../contexts/ImportContext';
import { fileUpload } from '../../api/importApi';

import classes from './Import.module.css';
import { Grid } from '@mui/material';

const ITEMS_PER_PAGE = 25;

const TablePaginationWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '20px',
  marginBottom: '30px',
}));

const Import = () => {
  const [page, setPage] = useState(1);
  const [updatedPage, setUpdatedPage] = useState(1);
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState('');
  const [data, setData] = useState([]);
  const [updatedData, setUpdatedData] = useState([]);
  const [numberOfItems, setNumberOfItems] = useState(0);
  const [numberOfUpdatedItems, setNumberOfUpdatedItems] = useState(0);
  const [renderedData, setRenderedData] = useState([]);
  const [renderedUpdatedData, setRenderedUpdatedData] = useState([]);
  const uploadInputRef = useRef(null);
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [showPagination, setShowPagination] = useState(false);
  const [showUpdatedPagination, setShowUpdatedPagination] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const { importedItems } = useContext(ImportContext);
  const { updatedItems } = useContext(ImportContext);
  const [updatedItemsArray, setUpdatedItemsArray] = updatedItems;
  const [importItemsArray, setImportedItemsArray] = importedItems;

  const paginateArray = (array = [], pageSize, pageNumber) => {
    return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
  };

  useEffect(() => {
    setLoadingSpinner(true);
    setTimeout(() => {
      setRenderedData(paginateArray(data, ITEMS_PER_PAGE, page));
      setRenderedUpdatedData(
        paginateArray(updatedData, ITEMS_PER_PAGE, updatedPage)
      );
      setNumberOfItems(data.length);
      setNumberOfUpdatedItems(data.length);
      setLoadingSpinner(false);
    }, 500);
  }, [page, data, updatedPage, updatedData]);

  useEffect(() => {
    if (importItemsArray) {
      setData([...importItemsArray]);
      if (importItemsArray.length !== 0) {
        setShowPagination(true);
      }
    }

    if (updatedItemsArray) {
      setUpdatedData([...updatedItemsArray]);
      if (updatedItemsArray.length !== 0) {
        setShowUpdatedPagination(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearImport = () => {
    setRenderedData([]);
    setShowPagination(false);
    setShowUpdatedPagination(false);
    setFile(null);
    setFileName('');
    setSubmitButtonDisabled(true);
  };

  const fileHandler = (event) => {
    setFile(event.target.files[0]);
    setFileName(event.target.files[0].name);
    setSubmitButtonDisabled(false);
  };
  const submitHandler = () => {
    const getResponse = async () => {
      const resp = await fileUpload(file);
      const { nonExistingEntries = [], existingEntries = [] } = resp;
      setData(nonExistingEntries);
      setUpdatedData(existingEntries);
      setShowPagination(nonExistingEntries.length > 0);
      setShowUpdatedPagination(updatedData.length > 0);
      setImportedItemsArray(nonExistingEntries);
      setUpdatedItemsArray(existingEntries);
    };
    getResponse();
  };

  const renderUpdate = (newData) => {
    setUpdatedData(newData);
  };

  return (
    <>
      <Navigation title={<Trans id="import.title.AppBarTitle" />} />
      <Container maxWidth="lg" className={classes.container}>
        <Fragment>
          <span style={{ marginRight: '10px' }}>{fileName}</span>
          <input
            ref={uploadInputRef}
            type="file"
            style={{ display: 'none' }}
            onChange={fileHandler}
            onClick={(event) => {
              event.target.value = null;
            }}
          />
          <Button
            onClick={() =>
              uploadInputRef.current && uploadInputRef.current.click()
            }
            variant="contained"
            style={{
              textDecoration: 'none',
              color: 'white',
              marginRight: '10px',
            }}
            sx={{
              '@media screen and (max-width: 523px)': {
                marginBottom: '20px',
              },
            }}
          >
            <Trans id="import.uploadFile.button" />
          </Button>
        </Fragment>

        <Button
          disabled={submitButtonDisabled}
          variant="outlined"
          color="secondary"
          onClick={submitHandler}
        >
          <Trans id="import.importFile.button" />
        </Button>

        <Button
          variant="outlined"
          color="error"
          style={{ marginLeft: '10px' }}
          onClick={clearImport}
        >
          <Trans id="import.remove.button" />
        </Button>
        {loadingSpinner ? (
          <Grid container spacing={2} justifyContent="center">
            <CircularProgress
              color="secondary"
              style={{ marginTop: '200px' }}
            />
          </Grid>
        ) : (
          <Grid container spacing={2} justifyContent="center">
            {renderedUpdatedData.length > 0 ? (
              <Grid item xs={12} md={6}>
                <h3>
                  <Trans id="import.list.updated" />
                </h3>

                <List>
                  {renderedUpdatedData.map((row, index) => (
                    <ImportItem
                      key={index}
                      data={row}
                      updated
                      renderUpdate={renderUpdate}
                    ></ImportItem>
                  ))}
                </List>

                {showUpdatedPagination ? (
                  <TablePaginationWrapper>
                    <Stack spacing={2}>
                      <Pagination
                        variant="outlined"
                        shape="rounded"
                        page={page}
                        onChange={(e, val) => setUpdatedPage(val)}
                        showFirstButton={
                          numberOfUpdatedItems > ITEMS_PER_PAGE * 2
                        }
                        showLastButton={
                          numberOfUpdatedItems > ITEMS_PER_PAGE * 2
                        }
                        defaultPage={1}
                        count={Math.ceil(numberOfUpdatedItems / ITEMS_PER_PAGE)}
                      />
                    </Stack>
                  </TablePaginationWrapper>
                ) : (
                  ''
                )}
              </Grid>
            ) : null}
            {renderedData.length > 0 ? (
              <Grid item xs={12} md={6}>
                <h3>
                  <Trans id="import.list.notExisting" />
                </h3>

                <List>
                  {renderedData.map((row, index) => (
                    <ImportItem key={index} data={row}></ImportItem>
                  ))}
                </List>

                {showPagination ? (
                  <TablePaginationWrapper>
                    <Stack spacing={2}>
                      <Pagination
                        variant="outlined"
                        shape="rounded"
                        page={page}
                        onChange={(e, val) => setPage(val)}
                        showFirstButton={numberOfItems > ITEMS_PER_PAGE * 2}
                        showLastButton={numberOfItems > ITEMS_PER_PAGE * 2}
                        defaultPage={1}
                        count={Math.ceil(numberOfItems / ITEMS_PER_PAGE)}
                      />
                    </Stack>
                  </TablePaginationWrapper>
                ) : (
                  ''
                )}
              </Grid>
            ) : null}
          </Grid>
        )}
      </Container>
    </>
  );
};

export default Import;
