export const contentOptions = [
  '5ml',
  '10ml',
  '15ml',
  '20ml',
  '25ml',
  '30ml',
  '35ml',
  '40ml',
  '50ml',
  '60ml',
  '65ml',
  '70ml',
  '75ml',
  '80ml',
  '90ml',
  '100ml',
  '105ml',
  '125ml',
  '150ml',
  '200ml',
  '236ml',
  '240ml',
  '250ml',
];
