import { Link, useHistory, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Trans } from '@lingui/react';
import { styled, alpha } from '@mui/material/styles';
import {
  Button,
  InputBase,
  Menu,
  MenuItem,
  IconButton,
  Badge,
  Box,
  AppBar,
  Toolbar,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreIcon from '@mui/icons-material/MoreVert';
import SettingsIcon from '@mui/icons-material/Settings';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AddIcon from '@mui/icons-material/Add';
import HomeIcon from '@mui/icons-material/Home';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ReactLoading from 'react-loading';
import Switch from '@mui/material/Switch';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useAuth0 } from '@auth0/auth0-react';
import classes from './Navigation.module.css';
import ClearIcon from '@mui/icons-material/Clear';
import { updateFreeDelivery } from '../../api/updateFreeDeliveryApi';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: '15px',
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(5),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'relative',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: '10px',
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const Navigation = (props) => {
  const { logout, user } = useAuth0();

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const history = useHistory();
  const [shippingToggle, setShippingToggle] = useState(false);
  const [isLandingPage, setIsLandingPage] = useState(true);
  const [shippingDialog, setShippingDialog] = useState(false);
  const [shippingIsLoading, setShippingIsLoading] = useState(false);
  const [shippingError, setShippingError] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== '/') {
      setIsLandingPage(false);
    }
  }, [location]);

  const handleShippingToggle = () => {
    setShippingToggle((prev) => !prev);
  };

  const shippingClickHandler = () => {
    setShippingDialog(true);
  };

  const closeShippingPopup = () => {
    setShippingDialog(false);
    setShippingError(false);
  };
  const changeFreeShippingHandler = () => {
    setShippingIsLoading(true);

    updateFreeDelivery(shippingToggle).then((response) => {
      if (response && response !== 201) {
        setShippingIsLoading(false);
        setShippingError(true);
      } else {
        setShippingIsLoading(false);
        setShippingDialog(false);
      }
    });
  };
  const goBackHandler = () => {
    history.goBack();
  };
  const homeHandler = () => {
    history.push('/');
  };
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = (event) => {
    setAnchorEl(null);
    handleMobileMenuClose();
    if (event.target.id === 'logout') {
      logout();
    }
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';

  const mobileMenuId = 'primary-search-account-menu-mobile';

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <Link to="/import" style={{ textDecoration: 'none', color: 'black' }}>
        <MenuItem>
          <IconButton
            size="large"
            aria-label="show 4 new mails"
            color="inherit"
          >
            <Badge badgeContent={0} color="error">
              <UploadFileIcon style={{ color: 'black' }} />
            </Badge>
          </IconButton>
          <Trans id="itemsOverviewPage.import.mobileMenu"></Trans>
        </MenuItem>
      </Link>
      <MenuItem onClick={shippingClickHandler}>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={0} color="error">
            <LocalShippingIcon />
          </Badge>
        </IconButton>
        <Trans id="Navigation.localShipping.mobileMenu" />
      </MenuItem>

      <Link to="/config" style={{ textDecoration: 'none', color: 'black' }}>
        <MenuItem>
          <IconButton
            size="large"
            aria-label="show 17 new notifications"
            color="inherit"
          >
            <Badge badgeContent={0} color="error">
              <SettingsIcon style={{ color: 'black' }} />
            </Badge>
          </IconButton>

          <Trans id="itemsOverviewPage.configuration.mobileMenu" />
        </MenuItem>
      </Link>

      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <Trans id="itemsOverviewPage.user.mobileMenu" />
      </MenuItem>
    </Menu>
  );

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem>{user.nickname}</MenuItem>
      <MenuItem onClick={handleMenuClose} id="logout">
        <Trans id="ItemsOverviewPage.logout.menuItem" />
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        open={shippingDialog}
      >
        <DialogTitle>
          <Trans id="navigation.freeShippingDialog.dialogTitle" />
        </DialogTitle>
        <DialogContent>
          <Trans id="navigation.freeShippingContent.dialogContent" />
          <br></br>
          <br></br>
          <Trans id="Navigation.shippingFalse.toggle" />
          <Switch checked={shippingToggle} onChange={handleShippingToggle} />
          <Trans id="Navigation.shippingTrue.toggle" />
          <br></br>
          <br></br>
          {shippingError ? (
            <p style={{ color: 'red' }}>
              <Trans
                id="Navigation.shoppingError.error"
                style={{ color: 'red' }}
              />
            </p>
          ) : (
            ''
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={closeShippingPopup}>
            <Trans id="navigation.declineShipping.dialogActions" />
          </Button>

          <Button onClick={changeFreeShippingHandler} style={{ color: 'red' }}>
            {shippingIsLoading ? (
              <ReactLoading type="bars" color="red" width={30} height={30} />
            ) : (
              <Trans id="navigation.changeShipping.dialogActionsConfirm" />
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <AppBar sx={{ position: 'static', top: 0, left: 0, right: 0 }}>
        <Toolbar color="primary">
          {isLandingPage ? null : (
            <>
              <Button style={{ color: 'white' }} onClick={goBackHandler}>
                <ArrowBackIcon />
              </Button>
              <Button style={{ color: 'white' }} onClick={homeHandler}>
                <HomeIcon />
              </Button>
            </>
          )}
          {props.title}
          <Box sx={{ flexGrow: 1 }} />
          {isLandingPage ? (
            <>
              <Search>
                <StyledInputBase
                  id="searchBar"
                  value={props?.searchValue}
                  onChange={props.searchHandler}
                  placeholder="Tražilica..."
                  inputProps={{ 'aria-label': 'search' }}
                  onKeyDown={props.enterSubmitSearchHandler}
                />
                <IconButton
                  onClick={props.clearSearchValue}
                  className={classes.searchRemoveButton}
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <ClearIcon />
                </IconButton>
              </Search>
              <Button
                onClick={props.submitSearchHandler}
                style={{ color: 'white' }}
              >
                <SearchIconWrapper>
                  <SearchIcon onClick={props.submitSearchHandler} />
                </SearchIconWrapper>
              </Button>
            </>
          ) : (
            ''
          )}
          <Box sx={{ flexGrow: 1 }} />
          <Link to="/item" style={{ textDecoration: 'none' }}>
            <AddIcon style={{ color: 'white' }} />
          </Link>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <IconButton
              size="large"
              aria-label="show 4 new mails"
              color="inherit"
            >
              <Badge badgeContent={0} color="error">
                <Link to="/import" style={{ textDecoration: 'none' }}>
                  <UploadFileIcon style={{ color: 'white' }} />
                </Link>
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              aria-label="show 4 new mails"
              color="inherit"
              onClick={shippingClickHandler}
            >
              <Badge badgeContent={0} color="error">
                <LocalShippingIcon />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <Badge badgeContent={0} color="error">
                <Link to="/config" style={{ textDecoration: 'none' }}>
                  <SettingsIcon style={{ color: 'white' }} />
                </Link>
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
};
export default Navigation;
