import React, { createContext, useState } from 'react';

export const ImportContext = createContext();

export const ImportContextProvider = ({ children }) => {
  const [importedItemsArray, setImportedItemsArray] = useState([]);
  const [updatedItemsArray, setUpdatedItemsArray] = useState([]);

  const store = {
    importedItems: [importedItemsArray, setImportedItemsArray],
    updatedItems: [updatedItemsArray, setUpdatedItemsArray],
  };
  return (
    <ImportContext.Provider value={store}>{children}</ImportContext.Provider>
  );
};
